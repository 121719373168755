@import "./icons.scss";

.container {
	position: absolute;
	left: 0%;
	top: 0px;
	transform: translateX(100%);
	height: 100dvh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 120px;
	filter: blur(10px);
	z-index: 50;
	background: linear-gradient(180deg, #00000066 -26.17%, rgba(24, 22, 22, 0) 100%),
		linear-gradient(180deg, #373742 0%, #121316 100%);
	opacity: 0;
	overflow-y: auto;
	overflow-x: hidden;
	// transition: linear 0.3s allow-discrete;
	transition-timing-function: linear;
	transition-duration: 0.3s;
	transition-behavior: allow-discrete;
	display: none;

	&._show {
		transform: translateX(0%);
		filter: blur(0px);
		opacity: 1;
		display: flex;

		@starting-style {
			transform: translateX(100%);
			opacity: 0;
		}
	}

	&::before {
		content: "";
		display: inline-block;
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		max-height: 100vh;
		height: 100%;
		background-image: $background;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 300%;
		z-index: 1;
	}
}

.content {
	position: relative;
	z-index: 2;
	max-width: 550px;
	width: 100%;
	min-height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 62px 20px 0px 20px;
}

.backContainer {
	width: 100%;

	.back {
		font-family: "Kan-medium", sans-serif;
		font-size: 16px;
		color: rgba(255, 255, 255, 1);
		&:before {
			content: "";
			display: inline-block;
			width: 13px;
			height: 10px;
			background-image: $arrowBack;
			margin-right: 3px;
		}
	}
}

.pgContainer {
	width: 100%;
	margin-top: 10px;
	// padding: 0px 23px;

	.progressLineContainer {
		width: 100%;
		padding: 12px;
		background-color: rgba(47, 49, 62, 1);
		border-radius: 15px;

		.progress {
			position: relative;
			width: 100%;
			height: 12px;
			background-color: rgba(0, 0, 0, 0.25);
			border-radius: 12px;
			// overflow: hidden;

			.lineGrad {
				width: 100%;
				height: 100%;
				border-radius: 12px;
				background: linear-gradient(
					90deg,
					#ff5251 0%,
					#ff7a51 20%,
					#ffab51 37.5%,
					#ffd951 53%,
					#ffff52 67%,
					#b6ff51 85.5%,
					#51ff51 100%
				);
			}

			.lineShadow {
				position: absolute;
				right: 0px;
				top: -2px;
				width: var(--lineWidth);
				height: calc(100% + 4px);
				display: flex;
				align-items: center;
				background-color: rgb(47, 49, 62);
				animation: lineResize ease-in-out infinite 2s;

				@keyframes lineResize {
					0% {
						width: var(--lineWidth);
					}
					80% {
						width: 0%;
					}
					100% {
						width: var(--lineWidth);
					}
				}

				.lineBg {
					width: 100%;
					height: 12px;
					background-color: rgba(0, 0, 0, 0.25);
					border-radius: 0px 12px 12px 0px;
				}
			}

			.circle {
				position: absolute;
				top: -4px;
				width: 12px;
				height: 12px;
				border: 4px solid white;
				border-radius: 50%;
				box-sizing: content-box;
			}
		}

		.chanceContainer {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 8px;

			.makeDeposit {
				width: 173px;
				height: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 2px;
				background-color: rgba(255, 255, 255, 0.09);
				border-radius: 50px;

				span {
					font-family: "Kan-bold", sans-serif;
					font-size: 14px;
					text-transform: uppercase;
					color: rgb(255, 255, 255);
				}

				&:after {
					content: "";
					display: inline-block;
					width: 13px;
					height: 13px;
					background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE1IDE2Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMSA3YTEgMSAwIDAgMCAwIDJWN1ptMTMuNzA3IDEuNzA3YTEgMSAwIDAgMCAwLTEuNDE0TDguMzQzLjkyOUExIDEgMCAwIDAgNi45MyAyLjM0M0wxMi41ODYgOGwtNS42NTcgNS42NTdhMSAxIDAgMSAwIDEuNDE0IDEuNDE0bDYuMzY0LTYuMzY0Wk0xIDloMTNWN0gxdjJaIi8+PC9zdmc+);
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					animation: arrowMove linear infinite 1.7s;
				}

				@keyframes arrowMove {
					0% {
						transform: translateX(0px);
					}
					50% {
						transform: translateX(10px);
					}
					100% {
						transform: translateX(0px);
					}
				}
			}

			.banner {
				width: 173px;
				height: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 2px;
				background-color: rgba(29, 30, 38, 1);
				border-radius: 50px;
				border: 2px solid rgba(255, 255, 255, 0.16);

				&:after {
					content: "";
					display: inline-block;
					width: 17px;
					height: 17px;
					background-image: $proc;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
				}

				span {
					font-family: "Kan-medium", sans-serif;
					font-size: 14px;
					text-transform: uppercase;
					color: rgba(255, 255, 255, 1);
				}
			}

			.proc {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;

				span {
					line-height: 100%;
					&:first-child {
						font-family: "K-thin", sans-serif;
						font-size: 32px;
						color: rgba(255, 255, 255, 1);
					}
					&:last-child {
						font-family: "K-thin", sans-serif;
						font-size: 12px;
						color: rgba(255, 255, 255, 0.31);
					}
				}
			}
		}
	}
}

.wining {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px;
	border-radius: 15px;
	background-color: rgba(47, 49, 62, 1);
	margin-top: 10px;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 20px;
		padding: 1px;
		background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
		-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		-webkit-mask-composite: destination-out;
		mask-composite: exclude;
		pointer-events: none;
	}

	.title {
		text-align: center;
		text-transform: uppercase;
		font-family: "K-semi-bold", sans-serif;
		font-size: 20px;
		color: rgba(255, 255, 255, 1);

		span {
			font-family: "K-extra-bold", sans-serif;
			color: rgba(255, 168, 28, 1);
		}
	}

	.text {
		font-family: "K-regular", sans-serif;
		font-size: 14px;
		color: rgba(242, 242, 242, 1);
		text-align: center;
		margin-top: 15px;
	}
}

.buttons {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	.button {
		width: 140px;
		height: 55px;
		color: rgba(255, 255, 255, 1);
		font-family: "K-bold", sans-serif;
		font-size: 16px;
		text-transform: uppercase;
		border-radius: 19px;

		&._makeDeposit {
			margin-top: 16px;
			background-color: rgba(255, 168, 28, 1);
		}

		&._help {
			position: relative;
			margin-top: 16px;
			// background-color: rgba(29, 30, 38, 1);
			background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			z-index: 0;

			&:before {
				content: "";
				position: absolute;
				display: inline-block;
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				left: 2px;
				top: 2px;
				border-radius: 19px;
				background-color: rgba(29, 30, 38, 1);
				// background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
				z-index: -1;
			}
		}
	}
}

.blockVideo {
	width: 100%;
	aspect-ratio: 2/1;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #242424;
	margin-top: 16px;
	border-radius: 7px;
	background-image: url("../../public/images/preview.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	.play {
		width: 28px;
		height: 28px;
		background-image: $playVideo;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		cursor: pointer;
	}
}

.help {
	max-width: 275px;
	width: 100%;
	padding: 15px;
	border-radius: 15px;
	background-color: rgba(47, 49, 62, 1);
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 22px;

	.avatar {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	.contentHelp {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;

		.username {
			font-family: "Inter-light", sans-serif;
			font-size: 16px;
			color: rgba(255, 255, 255, 1);
		}

		.info {
			font-family: "K-regular", sans-serif;
			font-size: 11px;
			color: rgba(242, 242, 242, 0.33);
		}

		button {
			width: fit-content;
			height: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 3px;
			padding: 0px 16px;
			background-color: rgba(0, 175, 250, 1);
			border-radius: 15px;

			span {
				font-family: "Inter-bold";
				font-size: 14px;
				color: rgba(255, 255, 255, 1);
				text-transform: uppercase;

				&:before {
					content: "";
					display: inline-block;
					width: 14px;
					height: 11px;
					background-image: $tgBtn;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					margin-right: 3px;
				}
			}
		}
	}
}

.buttonsHelp {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-top: 16px;

	.button {
		position: relative;
		background-color: rgba(0, 176, 255, 1);
		z-index: 0;
		width: 220px;
		height: 48px;
		transform: translateX(29px);
		color: rgba(255, 255, 255, 1);
		font-family: "K-bold", sans-serif;
		font-size: 16px;
		text-transform: uppercase;
		border-radius: 19px;

		span {
			position: relative;
			display: inline-block;
			transform: translateX(14px);
			&:before {
				content: "";
				position: absolute;
				left: -28px;
				top: 3px;
				display: block;
				width: 19px;
				height: 16px;
				background-image: $tg;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}

		&:nth-child(1) {
			img {
				// content: "";
				position: absolute;
				top: 0px;
				left: -58px;
				display: block;
				width: 48px;
				height: 48px;
				border-radius: 50%;
				object-fit: cover;
				object-position: 20%;
				// background-image: url(../../public/images/ind.png);
				// background-position: 20% 0%;
				// background-repeat: no-repeat;
				// background-size: 200%;
			}
		}
		&:nth-child(2) {
			img {
				// content: "";
				position: absolute;
				top: 0px;
				left: -58px;
				display: block;
				width: 48px;
				height: 48px;
				border-radius: 50%;
				// background-image: url(../../public/images/tgk.png);
				// background-repeat: no-repeat;
				// background-size: contain;
				// background-position: center;
			}
		}

		&:after {
			content: "";
			position: absolute;
			display: inline-block;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			left: 2px;
			top: 2px;
			border-radius: 19px;
			background-color: rgba(29, 30, 38, 1);
			// background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			z-index: -1;
		}
	}
}

.title {
	text-align: center;
	text-transform: uppercase;
	font-family: "K-semi-bold", sans-serif;
	font-size: 20px;
	color: rgba(255, 255, 255, 1);

	span {
		font-family: "K-extra-bold", sans-serif;
		background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.text {
	text-align: center !important;
	font-family: "K-regular", sans-serif !important;
	font-size: 14px !important;
	color: rgba(242, 242, 242, 1) !important;
	margin-top: 24px;
	span {
		font-family: "K-semi-bold", sans-serif;
		background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.text2 {
	text-align: center !important;
	font-family: "K-regular", sans-serif !important;
	font-size: 14px !important;
	color: rgba(242, 242, 242, 1) !important;
	margin-top: 24px;
	span {
		font-family: "K-semi-bold", sans-serif;
	}
}

.titleStep {
	text-align: center !important;
	font-family: "K-medium", sans-serif !important;
	font-size: 14px !important;
	color: rgba(242, 242, 242, 1) !important;

	span {
		margin-right: 10px;
		font-size: 16px;
		font-family: "K-semi-bold", sans-serif;
		background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.howPlay {
	&Container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: rgba(26, 27, 35, 1);
		border-radius: 15px;
		padding: 15px;
		margin-top: 15px;
	}

	&Image {
		width: 145px;
		height: 202px;
		margin-top: 15px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		overflow: hidden;

		img {
			border-radius: 15px;
			object-fit: contain;
			image-rendering: pixelated; /* Використовується для піксель-арту */
		}
	}

	.step {
		position: relative;
		width: calc(100% - 18px);
		min-height: 52px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: 18px;
		margin-top: 20px;
		padding: 10px 20px 10px 36px;
		background-color: rgba(39, 40, 50, 1);
		border-radius: 15px;

		.num {
			position: absolute;
			left: -18px;
			top: 50%;
			transform: translateY(-50%);
			width: 36px;
			height: 36px;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			font-family: "K-bold", sans-serif;
			color: rgba(39, 39, 39, 1);
		}

		p {
			text-align: start;
			font-family: "K-medium", sans-serif;
			font-size: 14px;
			color: rgba(242, 242, 242, 1);
			span {
				background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
	}

	.imageIncraceChance {
		width: 220px;
		height: 80px;
		margin-top: 20px;
		background-image: url(../../public/images/incraceChance.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	.arrowBottom {
		width: 22px;
		height: 22px;
		background-image: $arrowBack;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		transform: rotateZ(-90deg);
		margin-top: 10px;
	}

	.imagePrevRound {
		width: 243px;
		height: 40px;
		margin-top: 20px;
		background-image: url(../../public/images/prevRound.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
}

.history {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	&Header {
		width: 100%;
		margin-bottom: 20px;
	}

	&Stats {
		position: relative;
		width: 100%;
		height: 52px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
		padding: 0px 10px;
		background-color: #1c1c1c;
		border-radius: 17px;
		background-image: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
		background-repeat: no-repeat;
		background-position: center;

		&._t2 {
			border-radius: 8px;
			background-image: none;
			background-color: rgba(47, 49, 62, 1);
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 100%;
			background-image: $historyBackground;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 70%;
			z-index: 0;
		}

		.sec1 {
			position: relative;
			display: flex;
			align-items: center;
			gap: 11px;
			z-index: 1;

			&._anim {
				&::before {
					animation-name: move;
					animation-duration: 1s;
					animation-timing-function: linear;
					animation-iteration-count: infinite;
				}

				@keyframes move {
					0% {
						transform: translateY(0px);
					}

					50% {
						transform: translateY(-22px);
					}

					100% {
						transform: translateY(0px);
					}
				}
			}

			&::before {
				content: "";
				display: inline-block;
				width: 22px;
				height: 31px;
				background-image: $arrowTop;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				transform: rotateZ(180deg);
			}

			.titles {
				display: flex;
				flex-direction: column;

				span {
					color: rgba(35, 35, 42, 1);
					line-height: 100%;
					text-transform: uppercase;

					&:first-child {
						font-family: "K-extra-bold";
						font-size: 14px;
					}

					&:last-child {
						font-family: "K-thin";
						font-size: 18px;
					}
				}
			}
		}

		.sec2 {
			position: relative;
			display: flex;
			align-items: center;
			gap: 5px;
			transition: linear 0.5s;
			z-index: 1;

			&._rotate {
				transform: rotateX(90deg);
				opacity: 0;
			}

			&::before {
				content: "";
				display: inline-block;
				width: 24px;
				height: 24px;
				background-color: rgba(35, 35, 42, 1);
				border-radius: 7px;
				background-image: $users;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 14px 14px;
			}

			.titles {
				display: flex;
				flex-direction: column;

				&._t2 {
					span {
						color: rgba(236, 236, 236, 1);
						line-height: 100%;

						&:first-child {
							font-family: "K-medium";
							font-size: 14px;
						}

						&:last-child {
							font-family: "K-thin";
							font-size: 12px;
						}
					}
				}

				span {
					color: rgba(52, 52, 52, 1);
					line-height: 100%;

					&:first-child {
						font-family: "K-medium";
						font-size: 14px;
					}

					&:last-child {
						font-family: "K-thin";
						font-size: 12px;
					}
				}
			}
		}

		.sec3 {
			position: relative;
			display: flex;
			align-items: center;
			gap: 5px;
			transition: linear 0.5s;
			z-index: 1;

			&._rotate {
				transform: rotateX(90deg);
				opacity: 0;
			}

			&::before {
				content: "";
				display: inline-block;
				width: 24px;
				height: 24px;
				background-color: rgba(35, 35, 42, 1);
				border-radius: 7px;
				background-image: $wallet;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 17px 17px;
			}

			.titles {
				display: flex;
				flex-direction: column;

				&._t2 {
					span {
						color: rgba(236, 236, 236, 1);
						line-height: 100%;

						&:first-child {
							font-family: "K-medium";
							font-size: 14px;
						}

						&:last-child {
							font-family: "K-thin";
							font-size: 12px;
						}
					}
				}

				span {
					color: rgba(52, 52, 52, 1);
					line-height: 100%;

					&:first-child {
						font-family: "K-medium";
						font-size: 14px;
					}

					&:last-child {
						font-family: "K-thin";
						font-size: 12px;
					}
				}
			}
		}
	}

	&Body {
		width: 100%;
		display: flex;
		flex-direction: column;

		.coeff {
			width: fit-content;
			padding: 0px 20px;
			height: 26px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: auto;
			background-color: #1f1f1f;
			font-family: "Inter-extra-bold", sans-serif;
			font-size: 14px;
			border-radius: 17px;
			transition: transform 0.3s ease-in-out;

			&._blue {
				min-width: 56px;
				color: rgba(0, 176, 255, 1);
			}
			&._purple {
				min-width: 65px;
				color: rgba(124, 77, 255, 1);
			}
			&._pink {
				min-width: 65px;
				color: rgba(192, 23, 180, 1);
			}
			&._gold {
				min-width: 75px;
				color: rgba(255, 187, 0, 1);
			}
		}

		.titleResult {
			font-family: "Inter-extra-bold", sans-serif;
			font-size: 16px;
			text-transform: uppercase;
			text-align: end;
			background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.usersTitle {
			font-family: "K-medium", sans-serif !important;
			font-size: 16px !important;
		}

		.showMore {
			font-family: "K-medium", sans-serif !important;
			font-size: 16px !important;
			color: rgba(255, 255, 255, 1);
			text-decoration: underline;
			margin: 20px 0px;
		}
	}
}

.predictionsLimit {
	.predictionLimit {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		& > section {
			padding: 0;

			& > div {
				&:first-child {
					svg {
						display: none;
					}
					span {
						color: rgba(255, 255, 255, 1);
					}
				}
			}
		}
	}

	.incraseChanse {
		width: 100%;
		& > section {
			width: 100% !important;
			padding: 0 !important;
		}
	}

	.predictions {
		position: relative;
		width: 100%;
		height: fit-content;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		background-color: rgba(40, 41, 52, 1);
		border-radius: 15px;
		padding: 17px;
		margin-top: 10px;

		&:before {
			content: "";
			position: absolute;
			left: -2px;
			top: -2px;
			width: calc(100% + 4px);
			height: calc(100% + 4px);
			background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			border-radius: 15px;
			z-index: -1;
		}

		.makeDeposit {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: rgba(31, 32, 40, 1);
			border-radius: 10px;
			padding: 13px;

			.sec1 {
				width: fit-content;
				display: flex;
				flex-direction: column;
				gap: 3px;

				.money {
					display: flex;
					align-items: center;
					gap: 3px;

					.sum {
						font-family: "Kan-bold", sans-serif;
						font-size: 20px;
						line-height: 100%;
						color: rgba(255, 255, 255, 1);
					}

					.arrow {
						width: 33px;
						height: 17px;
						background-image: $arrows;
						background-repeat: no-repeat;
						background-position: center;
						background-size: contain;
					}

					.energy {
						display: flex;
						align-items: center;
						position: relative;
						font-family: "Kan-bold", sans-serif;
						font-size: 20px;
						line-height: 100%;
						color: rgba(255, 255, 255, 1);

						&::after {
							content: "";
							display: inline-block;
							// position: absolute;
							right: -12px;
							top: 0px;
							width: 11px;
							height: 18px;
							background-image: $energy;
							background-position: center;
							background-size: contain;
							background-repeat: no-repeat;
						}
					}
				}

				.chance {
					font-family: "Inter-light", sans-serif;
					font-size: 10px;
					color: rgba(255, 255, 255, 1);
					text-transform: uppercase;

					span {
						font-family: "Inter-bold", sans-serif;
					}
				}

				.line {
					height: 6px;
					background-color: rgba(40, 41, 52, 1);
					border-radius: 8px;

					.grad_1 {
						width: 20%;
						height: 100%;
						background: linear-gradient(250.77deg, #e57505 8.76%, #ff4d47 89.29%);
						border-radius: 8px;
						animation: grad_1 linear infinite 4s 0s;

						@keyframes grad_1 {
							0% {
								width: 0%;
							}
							100% {
								width: 20%;
							}
						}
					}
					.grad_2 {
						width: 40%;
						height: 100%;
						background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
						border-radius: 8px;
						animation: grad_2 linear infinite 4s 0s;

						@keyframes grad_2 {
							0% {
								width: 0%;
							}
							100% {
								width: 40%;
							}
						}
					}
					.grad_3 {
						width: 60%;
						height: 100%;
						background: linear-gradient(90deg, #f2b64b 0%, #0ad683 100%);
						border-radius: 8px;
						animation: grad_3 linear infinite 4s 0s;

						@keyframes grad_3 {
							0% {
								width: 0%;
							}
							100% {
								width: 60%;
							}
						}
					}
					.grad_4 {
						width: 100%;
						height: 100%;
						background: linear-gradient(250.77deg, #07dd72 8.76%, #0ad18c 92.21%);
						border-radius: 8px;
						animation: grad_4 linear infinite 4s 0s;

						@keyframes grad_4 {
							0% {
								width: 0%;
							}
							100% {
								width: 100%;
							}
						}
					}
				}
			}

			.sec2 {
				button {
					width: 96px;
					height: 41px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					border-radius: 10px;
					background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);

					span {
						font-family: "Kan-bold", sans-serif;
						color: rgba(29, 30, 38, 1);

						&:first-child {
							font-size: 14px;
						}
						&:last-child {
							font-size: 10px;
						}
					}
				}
			}
		}
	}

	.free {
		width: 100%;
		padding: 9px 11px 20px 11px;
		background-color: rgba(47, 49, 62, 1);
		border-radius: 15px;
		margin-top: 15px;

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.time {
				width: fit-content;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 2px;
				padding: 3px 15px;
				background-color: rgba(30, 31, 36, 1);
				border-radius: 22px;

				span {
					display: inline-block;
					font-family: "Roboto-bold", sans-serif;
					font-size: 16px;
					color: rgba(255, 255, 255, 1);
				}
			}
		}

		.body {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 10px;

			.energy {
				position: relative;
				width: 86px;
				height: 45px;
				border-radius: 22px;

				.num {
					position: absolute;
					left: 2px;
					top: 2px;
					width: calc(100% - 4px);
					height: calc(100% - 4px);
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: rgb(56, 59, 71);
					border-radius: 22px;
					z-index: 1;

					span {
						position: relative;
						font-family: "Kan-bold", sans-serif;
						font-size: 24px;
						color: rgba(255, 255, 255, 1);
						z-index: 0;

						&:after {
							content: "";
							display: inline-block;
							width: 12px;
							height: 20px;
							background-image: $energy;
							background-repeat: no-repeat;
							background-position: center;
							background-size: contain;
							transform: translateY(2px) translateX(2px);
						}
					}
				}

				.bg {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: calc(100%);
					height: calc(100%);
					background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
					border-radius: 22px;
					z-index: 0;
				}
			}

			.info {
				font-family: "Inter-regular", sans-serif;
				font-size: 14px;
				color: rgba(255, 255, 255, 0.4);

				span {
					color: rgba(255, 255, 255, 1);
				}
			}
		}
	}
}

.framePlay {
	position: absolute;
	top: 90px;
	left: 0px;
	width: 100%;
	height: calc(100dvh - 90px - 100px);

	iframe {
		width: 100%;
		height: 100%;
	}

	video {
		object-fit: cover;
		object-position: top;
	}
}

.predictionLimitIS {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	width: calc(100% + 40px);
	margin-top: 13px;
	background-color: rgba(47, 49, 62, 1);
	padding: 0px 20px;

	.image {
		img {
			min-width: 120px;
			max-width: 120px;
			height: 120px;
		}
	}

	.info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 13px;

		span {
			font-size: 16px;
			font-family: Inter-regular, sans-serif;
			color: rgba(242, 242, 242, 1);
		}

		button {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			background: linear-gradient(250.77deg, #00ffd5 8.76%, #05bb72 92.21%);
			padding: 9px 8px;
			color: rgba(47, 49, 62, 1);
			font-size: 12px;
			font-family: "K-semi-bold", sans-serif;
			border-radius: 100px;
			overflow: hidden;

			svg {
				width: 8px;
				height: 13px;
			}

			&::after {
				content: "";
				position: absolute;
				top: -50%;
				right: -50%;
				bottom: -50%;
				left: -50%;
				background: linear-gradient(
					to bottom,
					rgba(229, 172, 142, 0),
					rgba(255, 255, 255, 0.5) 50%,
					rgba(229, 172, 142, 0)
				);
				transform: rotateZ(60deg) translate(-5em, 7.5em);
			}

			&:after,
			&:after {
				animation: sheen 2s forwards;
				animation-iteration-count: infinite;
				animation-fill-mode: forwards;
			}

			@keyframes sheen {
				100% {
					transform: rotateZ(60deg) translate(1em, -9em);
				}
			}
		}
	}
}

.limitSection {
	width: 100%;
	margin-top: 13px;
	& > section {
		padding: 0px;
	}
}
